<template>
  <div class="about">
    <div style="background: no-repeat center center / cover" :style="{ backgroundImage: 'url(' + require('@/assets/breadcumb.jpg') + ')' }" class="breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="breadcumb-title">{{ langContent.corporate }} </h2>
            <ul class="breadcumb-menu">
              <li>
                <router-link to="/">{{ langContent.home }}</router-link>
              </li>
              <li>{{ langContent.corporate }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="world-dentistry mb-5 mt--100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="wd-left-thumb">
              <img alt="delta plastik" class="shape3" src="@/assets/vs3.png">
              <img :src="data.img" alt="delta">
            </div>
          </div>
          <div class="col-md-6">
            <div class="wd-content">
              <div class="section-title style-two">
                <h2>{{ data.title }}</h2>
              </div>
              <div v-html="data.desc"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="doctor-contact-area ptb--120" :style="{ backgroundImage: 'url(' + require('@/assets/aboutBg.jpg') + ')' }">
      <div class="container">
        <div class="row align-items-stretch">
          <div class="col-lg-6">
            <div class="appnt-form h-100">
              <h4>{{ mission.title }}</h4>
              <p>
                {{mission.desc}}
              </p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="appnt-form h-100">
              <h4>{{ vision.title }}</h4>
              <p>
                {{ vision.desc}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="galery-area p-0 mt--100">
      <div class="container">
        <div id="container" class="row masonary_container">
          <div class="col-12"><span class="titleDocs">{{ langContent.quality }}</span></div>
          <div v-for="(item,index) in docs" class="grid col-md-4" @click="openLightboxOnSlide(index+1)">
            <div class="glry-item">
              <a class="">
                <img :src="item.img" :alt="item.title">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FsLightbox
        :slide="slide"
        :sources="docsImg"
        :toggler="toggler"
    />

  </div>
</template>

<script>
import FsLightbox from "fslightbox-vue/v3";
import axiosCustom from "../../axiosCustom";

export default {
  components: {FsLightbox},
  data() {
    return {
      toggler: false,
      data: [],
      mission: [],
      vision: [],
      docs: [],
      docsImg: [],
      langContent: [],
      slide: 1
    }
  },
  created() {
    axiosCustom
        .get('langfix')
        .then(response => {
          this.langContent = response.data[0]
          document.title = 'Delta Plastik | '+response.data[0].corporate
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('corporate')
        .then(response => {
          this.data = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('mission')
        .then(response => {
          this.mission = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('vision')
        .then(response => {
          this.vision = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('docs')
        .then(response => {
          let data = response.data
          for (let key in data) {
            this.docsImg.push(data[key].img)
          }
          this.docs = response.data
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods: {
    openLightboxOnSlide: function (number) {
      this.slide = number;
      this.toggler = !this.toggler;
    }
  },
  beforeRouteLeave(to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style scoped>
.titleDocs {
  font-family: 'Exo', sans-serif;
  text-align: center;
  display: block;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #e32c10;
}
</style>